import React, { useContext } from "react";
import {
    SttMensagemAviso,
    SttTranslateHook
} from '@stt-componentes/core';
import usuario from "../../signals/usuario";
import { useSignal } from "@preact/signals-react";
import { SttExibicaoMensagem } from "@stt-componentes/atendimento";
import { EVENTOS_SOCKET } from "../../common/Constants";
import socket from "../../signals/socket";
import { atendimentoEncerrado, idEvento, idSalaAtendimento } from "../../signals/eventos";

const ExibicaoMensagemContainer = ({ mensagens = [] }) => {
    const { strings } = useContext(SttTranslateHook.I18nContext);
    useSignal();

    const excluirMensagem = (msg) => {''
        const dadosExcluir = {
            idMensagemExcluida: msg.id
        }
        if (msg.anexo) {''
            dadosExcluir.idAnexo = msg.anexo._id;
            dadosExcluir.idEvento = idEvento.value;
        }
        socket.value.emit(EVENTOS_SOCKET.EXCLUIR_MENSAGEM, JSON.stringify(dadosExcluir));
    }

    return (
        mensagens.map((msg, index) => {
            if (msg.notificacao) {
                return <SttMensagemAviso
                    {...msg}
                    minWidth='10%'
                    mensagem={msg.mensagem}
                    key={index}
                    atendimento={msg.atendimento}
                />
            }

            const proprioUsuarioEnvio = usuario.value.idFuncionario === msg.responsavel?.id;
            let esconderNomeEnviador = false;
            const msgAnterior = mensagens[index - 1];
            if (msgAnterior?.responsavel && msg?.responsavel) {
                esconderNomeEnviador = msgAnterior.responsavel.id === msg.responsavel.id;
            }

            if (!(msg.dataHora instanceof Date)) {
                msg.dataHora = new Date(msg.dataHora)
            }

            return (
                <SttExibicaoMensagem
                    key={index}
                    proprioUsuarioEnvio={proprioUsuarioEnvio}
                    esconderNomeEnviador={esconderNomeEnviador}
                    usuario={{ nome: msg.responsavel.nome }}
                    strings={strings}
                    timestamp={msg.dataHora.getTime()}
                    minWidth='10%'
                    anexo={msg.anexo || null}
                    mensagem={msg.mensagem}

                    exibirMenu={!atendimentoEncerrado.value}
                    excluida={!!msg.excluida}
                    excluirMensagem={() => excluirMensagem(msg)}

                    alterarMensagem={(mensagemNova) => {
                        const dados = JSON.stringify({
                            idMensagemAlterada: msg.id,
                            mensagemNova
                        });
                        socket.value.emit(EVENTOS_SOCKET.ALTERAR_MENSAGEM, dados);
                    }}
                    alterada={(msg.mensagensAnteriores || []).length > 0}
                />
            )
        })
    );
};

export default ExibicaoMensagemContainer;